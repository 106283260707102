import { mapState } from "vuex";
import { eventBus } from "@/eventBus";

export default {
  props: ["isIphone", "isSP"],
  data: function() {
    const constObj = this.$store.state.constObj;
    return {
      placeholder: constObj.DEFAULT_INPUT_MESSAGE,
      inputMessage: "",
      keydownCode: "",
      onIME: false,
      styleField: {
        paddingBottom: "",
      },
      styleSend: {
        pointerEvents: "",
      },
    };
  },
  computed: {
    ...mapState(["constObj"]),
  },
  methods: {
    getResourceImg(fileName) {
      return require("@product/resource/" + fileName);
    },
    sendMessage() {
      if (this.inputMessage && !this.onIME) {
        this.$emit("sendMessage", this.inputMessage);
        this.clearInputMessage();
      } else {
        this.$refs.sendMessageInput.focus();
        this.onFocusInput();
      }
    },
    clearInputMessage() {
      this.inputMessage = "";
    },
    onFocusInput() {
      if (this.onIME) {
        this.$emit("onChangeInputMessage", this.inputMessage);
        this.$emit("updateIsFocusMessageInput", false);
        this.onIME = false;
        return;
      }
      if (this.isIphone) {
        this.styleField.paddingBottom = "36px";
        eventBus.$emit("suggestBottomUp");
      }
      this.$emit("onChangeInputMessage", this.inputMessage);
      this.$emit("updateIsFocusMessageInput", true);
    },
    offFocusInput() {
      if (this.isIphone) {
        this.styleField.paddingBottom = "0px";
        eventBus.$emit("suggestBottomDown");
        this.$emit("clearSuggestion");
        this.$emit("updateIsFocusMessageInput", false);
      }
    },
    onConpositionStart() {
      if (this.isIphone) {
        this.styleSend.pointerEvents = "none";
      }
      this.onIME = true;
    },
    onCompositionend() {
      this.$emit("onChangeInputMessage", this.inputMessage);
      this.onIME = false;
      this.styleSend.pointerEvents = "";
    },
    onKeydownInput(e) {
      if (!this.isSP && this.onlyEnterKey(e)) {
        e.preventDefault();
      }
      this.keydownCode = e.keyCode;
    },
    onInput(e) {
      if (e.inputType === "deleteByCut" || e.inputType === "insertFromPaste") {
        this.$emit("onChangeInputMessage", this.inputMessage);
      }
    },
    onKeyupInput(e) {
      if (
        !this.isSP &&
        e.keyCode === this.keydownCode &&
        this.onlyEnterKey(e)
      ) {
        this.sendMessage();
        return;
      }
      //if (!this.onIME) {
      this.$emit("onChangeInputMessage", this.inputMessage);
      //}
    },
    onlyEnterKey(e) {
      return e.keyCode === 13 && !e.shiftKey && !e.ctrlKey && !e.altKey;
    },
  },
  created: function() {
    eventBus.$on("clearInputMessage", () => {
      this.clearInputMessage();
    });
  },
};
