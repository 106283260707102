var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c(
        "div",
        { style: _vm.customStyle },
        [
          !_vm.isWindowOpen
            ? _c(
                "a",
                {
                  staticClass: "saichat-launcher",
                  attrs: { id: "sci-chatwindow" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.toggleWindow($event)
                    }
                  }
                },
                [
                  _vm.constObj.LAUNCHER_ICON.outsideLeft.type === "img"
                    ? _c("img", {
                        staticClass: "saichat-launcher__outsideLeft animated",
                        class: [
                          _vm.constObj.LAUNCHER_ICON.outsideLeft.effect.motion,
                          _vm.constObj.LAUNCHER_ICON.outsideLeft.effect
                            .duration,
                          {
                            infinite:
                              _vm.constObj.LAUNCHER_ICON.outsideLeft.effect
                                .infinite
                          }
                        ],
                        attrs: {
                          src: _vm.getResourceImg(
                            _vm.constObj.LAUNCHER_ICON.outsideLeft.src[0]
                          )
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.constObj.LAUNCHER_ICON.outsideLeft.type === "icon"
                    ? _c(
                        "span",
                        {
                          staticClass: "saichat-launcher__outsideLeft",
                          class: {
                            "saichat-launcher__smallSize":
                              _vm.constObj.LAUNCHER_ICON.outsideLeft.size ===
                              "small",
                            "saichat-launcher__mediumSize":
                              _vm.constObj.LAUNCHER_ICON.outsideLeft.size ===
                              "medium",
                            "saichat-launcher__largeSize":
                              _vm.constObj.LAUNCHER_ICON.outsideLeft.size ===
                              "large"
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "animated",
                            class: [
                              _vm.constObj.LAUNCHER_ICON.outsideLeft.effect
                                .motion,
                              _vm.constObj.LAUNCHER_ICON.outsideLeft.effect
                                .duration,
                              {
                                infinite:
                                  _vm.constObj.LAUNCHER_ICON.outsideLeft.effect
                                    .infinite,
                                "saichat-launcher__smallIcon":
                                  _vm.constObj.LAUNCHER_ICON.outsideLeft
                                    .size === "small",
                                "saichat-launcher__mediumIcon":
                                  _vm.constObj.LAUNCHER_ICON.outsideLeft
                                    .size === "medium",
                                "saichat-launcher__largeIcon":
                                  _vm.constObj.LAUNCHER_ICON.outsideLeft
                                    .size === "large"
                              }
                            ],
                            attrs: {
                              icon: _vm.constObj.LAUNCHER_ICON.outsideLeft.src
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.constObj.LAUNCHER_BUTTON.type === "img"
                    ? _c("img", {
                        staticClass: "saichat-launcher__button animated",
                        class: [
                          _vm.constObj.LAUNCHER_BUTTON.motion,
                          _vm.constObj.LAUNCHER_BUTTON.duration,
                          {
                            infinite:
                              _vm.constObj.LAUNCHER_ICON.outsideLeft.effect
                                .infinite
                          }
                        ],
                        attrs: {
                          src: _vm.getResourceImg(
                            _vm.constObj.LAUNCHER_BUTTON.src
                          )
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  (_vm.constObj.LAUNCHER_ICON.left.type === "icon" &&
                    _vm.constObj.LAUNCHER_ICON.left.size === "medium") ||
                  _vm.constObj.LAUNCHER_ICON.left.size === "large"
                    ? _c(
                        "span",
                        {
                          staticClass: "saichat-launcher__insideLeft",
                          class: {
                            "saichat-launcher__mediumSize":
                              _vm.constObj.LAUNCHER_ICON.left.size === "medium",
                            "saichat-launcher__largeSize":
                              _vm.constObj.LAUNCHER_ICON.left.size === "large"
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "animated",
                            class: [
                              _vm.constObj.LAUNCHER_ICON.left.effect.motion,
                              _vm.constObj.LAUNCHER_ICON.left.effect.duration,
                              {
                                infinite: _vm.constObj.LAUNCHER_BUTTON.infinite,
                                "saichat-launcher__mediumIcon":
                                  _vm.constObj.LAUNCHER_ICON.left.size ===
                                  "medium",
                                "saichat-launcher__largeIcon":
                                  _vm.constObj.LAUNCHER_ICON.left.size ===
                                  "large"
                              }
                            ],
                            attrs: { icon: _vm.constObj.LAUNCHER_ICON.left.src }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.constObj.LAUNCHER_BUTTON.type === "default"
                    ? _c(
                        "span",
                        {
                          staticClass: "saichat-launcher__button animated",
                          class: [
                            _vm.constObj.LAUNCHER_BUTTON.motion,
                            _vm.constObj.LAUNCHER_BUTTON.duration,
                            {
                              infinite: _vm.constObj.LAUNCHER_BUTTON.infinite,
                              "saichat-launcher__leftIconButton":
                                _vm.constObj.LAUNCHER_ICON.left.size ===
                                  "medium" ||
                                _vm.constObj.LAUNCHER_ICON.left.size ===
                                  "large",
                              "saichat-launcher__rightIconButton":
                                _vm.constObj.LAUNCHER_ICON.right.size ===
                                  "medium" ||
                                _vm.constObj.LAUNCHER_ICON.right.size ===
                                  "large"
                            }
                          ],
                          staticStyle: { padding: "7px 16px", height: "auto" }
                        },
                        [
                          _vm.constObj.LAUNCHER_ICON.left.type === "img"
                            ? _c("img", {
                                staticClass:
                                  "saichat-launcher__insideLeft animated",
                                class: [
                                  _vm.constObj.LAUNCHER_ICON.left.effect.motion,
                                  _vm.constObj.LAUNCHER_ICON.left.effect
                                    .duration,
                                  {
                                    infinite:
                                      _vm.constObj.LAUNCHER_ICON.left.effect
                                        .infinite
                                  }
                                ],
                                attrs: {
                                  src: _vm.getResourceImg(
                                    _vm.constObj.LAUNCHER_ICON.left.src[0]
                                  )
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.constObj.LAUNCHER_ICON.left.type === "icon" &&
                          _vm.constObj.LAUNCHER_ICON.left.size === "small"
                            ? _c("font-awesome-icon", {
                                staticClass:
                                  "saichat-launcher__insideLeftSmall saichat-launcher__smallIcon animated",
                                class: [
                                  _vm.constObj.LAUNCHER_ICON.left.effect.motion,
                                  _vm.constObj.LAUNCHER_ICON.left.effect
                                    .duration,
                                  {
                                    infinite:
                                      _vm.constObj.LAUNCHER_ICON.left.effect
                                        .infinite
                                  }
                                ],
                                attrs: {
                                  icon: _vm.constObj.LAUNCHER_ICON.left.src
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "saichat-launcher__title" },
                            [_vm._v(_vm._s(_vm.launcherTitle))]
                          ),
                          _vm._v(" "),
                          _vm.constObj.LAUNCHER_ICON.right.type === "img"
                            ? _c("img", {
                                staticClass:
                                  "saichat-launcher__insideRight animated",
                                class: [
                                  _vm.constObj.LAUNCHER_ICON.right.effect
                                    .motion,
                                  _vm.constObj.LAUNCHER_ICON.right.effect
                                    .duration,
                                  {
                                    infinite:
                                      _vm.constObj.LAUNCHER_ICON.right.effect
                                        .infinite
                                  }
                                ],
                                attrs: {
                                  src: _vm.getResourceImg(
                                    _vm.constObj.LAUNCHER_ICON.right.src[0]
                                  )
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.constObj.LAUNCHER_ICON.right.type === "icon" &&
                          _vm.constObj.LAUNCHER_ICON.right.size === "small"
                            ? _c("font-awesome-icon", {
                                staticClass:
                                  "saichat-launcher__insideRightSmall saichat-launcher__smallIcon animated",
                                class: [
                                  _vm.constObj.LAUNCHER_ICON.right.effect
                                    .motion,
                                  _vm.constObj.LAUNCHER_ICON.right.effect
                                    .duration,
                                  {
                                    infinite:
                                      _vm.constObj.LAUNCHER_ICON.right.effect
                                        .infinite
                                  }
                                ],
                                attrs: {
                                  icon: _vm.constObj.LAUNCHER_ICON.right.src
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.constObj.LAUNCHER_ICON.outsideRight.type === "img"
                    ? _c("img", {
                        staticClass: "saichat-launcher__outsideRight animated",
                        class: [
                          _vm.constObj.LAUNCHER_ICON.outsideRight.effect.motion,
                          _vm.constObj.LAUNCHER_ICON.outsideRight.effect
                            .duration,
                          {
                            infinite:
                              _vm.constObj.LAUNCHER_ICON.outsideRight.effect
                                .infinite
                          }
                        ],
                        attrs: {
                          src: _vm.getResourceImg(
                            _vm.constObj.LAUNCHER_ICON.outsideRight.src[0]
                          )
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.constObj.LAUNCHER_ICON.outsideRight.type === "icon"
                    ? _c(
                        "span",
                        {
                          staticClass: "saichat-launcher__outsideRight",
                          class: {
                            "saichat-launcher__smallSize":
                              _vm.constObj.LAUNCHER_ICON.outsideRight.size ===
                              "small",
                            "saichat-launcher__mediumSize":
                              _vm.constObj.LAUNCHER_ICON.outsideRight.size ===
                              "medium",
                            "saichat-launcher__largeSize":
                              _vm.constObj.LAUNCHER_ICON.outsideRight.size ===
                              "large"
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "fa-lg animated",
                            class: [
                              _vm.constObj.LAUNCHER_ICON.outsideRight.effect
                                .motion,
                              _vm.constObj.LAUNCHER_ICON.outsideRight.effect
                                .duration,
                              {
                                infinite:
                                  _vm.constObj.LAUNCHER_ICON.outsideRight.effect
                                    .infinite,
                                "saichat-launcher__smallIcon":
                                  _vm.constObj.LAUNCHER_ICON.outsideRight
                                    .size === "small",
                                "saichat-launcher__mediumIcon":
                                  _vm.constObj.LAUNCHER_ICON.outsideRight
                                    .size === "medium",
                                "saichat-launcher__largeIcon":
                                  _vm.constObj.LAUNCHER_ICON.outsideRight
                                    .size === "large"
                              }
                            ],
                            attrs: {
                              icon: _vm.constObj.LAUNCHER_ICON.outsideRight.src
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  (_vm.constObj.LAUNCHER_ICON.right.type === "icon" &&
                    _vm.constObj.LAUNCHER_ICON.right.size === "medium") ||
                  _vm.constObj.LAUNCHER_ICON.right.size === "large"
                    ? _c(
                        "span",
                        {
                          staticClass: "saichat-launcher__insideRight",
                          class: {
                            "saichat-launcher__mediumSize":
                              _vm.constObj.LAUNCHER_ICON.right.size ===
                              "medium",
                            "saichat-launcher__largeSize":
                              _vm.constObj.LAUNCHER_ICON.right.size === "large"
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "animated",
                            class: [
                              _vm.constObj.LAUNCHER_ICON.right.effect.motion,
                              _vm.constObj.LAUNCHER_ICON.right.effect.duration,
                              {
                                infinite:
                                  _vm.constObj.LAUNCHER_ICON.right.effect
                                    .infinite,
                                "saichat-launcher__mediumIcon":
                                  _vm.constObj.LAUNCHER_ICON.right.size ===
                                  "medium",
                                "saichat-launcher__largeIcon":
                                  _vm.constObj.LAUNCHER_ICON.right.size ===
                                  "large"
                              }
                            ],
                            attrs: {
                              icon: _vm.constObj.LAUNCHER_ICON.right.src
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("transition", { attrs: { name: "show" } }, [
            _vm.isWindowOpen
              ? _c(
                  "div",
                  { ref: "saichatContainer", staticClass: "saichat-container" },
                  [
                    _c("Header", {
                      on: {
                        toggleWindow: _vm.toggleWindow,
                        clearMessages: _vm.clearMessages
                      }
                    }),
                    _vm._v(" "),
                    _c("Content", {
                      attrs: { isIphone: _vm.isIphone },
                      on: {
                        selectAnswer: _vm.selectAnswer,
                        scrollTop: _vm.scrollTop
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { ref: "inputContent" },
                      [
                        _vm.isSuggestion
                          ? _c("Suggestion", {
                              attrs: {
                                userInputChoices: _vm.userInputChoices,
                                isIphone: _vm.isIphone
                              },
                              on: {
                                updateSuggestionParams:
                                  _vm.updateSuggestionParams,
                                selectSuggest: _vm.selectSuggest,
                                clearSuggestion: _vm.clearSuggestion
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("UserInput", {
                          ref: "userInput",
                          attrs: { isSP: _vm.isSP, isIphone: _vm.isIphone },
                          on: {
                            onChangeInputMessage: _vm.onChangeInputMessage,
                            sendMessage: _vm.sendMessage,
                            updateIsFocusMessageInput:
                              _vm.updateIsFocusMessageInput
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.isEnqueteOpen
                      ? _c("Enquete", {
                          attrs: {
                            enquetes: _vm.getEnquetes,
                            enqueteMode: _vm.enqueteMode
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }