import { mapState, mapActions } from "vuex";

const STATUS = {
  SCRIPT_NOT_FOUND: "SCRIPT_NOT_FOUND",
  SEARCH_FAILED: "SEARCH_FAILED",
  RESOLVED: "RESOLVED",
  UNRESOLVED: "UNRESOLVED"
};

export default {
  props: ["enquetes", "enqueteMode"],
  data: function() {
    const constObj = this.$store.state.constObj;
    return {
      state: {},
      closing: false,
      unresolvedMessage: "",
      description: constObj.ENQUETE_RESOLVED_DESCRIPTION,
      submitMessage: constObj.ENQUETE_SUBMIT_BUTTON_MESSAGE,
      errorMessage: constObj.ENQUETE_ERROR_MESSAGE,
      progressValue: 0,
      progressBarColor: null,
      progressBarBackground: null,
      checkboxRequiredList: [],
      enqueteType: "",
      displayEnquetes: {},
      errorTargetList: []
    };
  },
  mounted() {
    const constObj = this.$store.state.constObj;
    this.enqueteType = this.getEnqueteType();
    this.description =
      this.enqueteType === STATUS.RESOLVED
        ? constObj.ENQUETE_RESOLVED_DESCRIPTION
        : constObj.ENQUETE_UNRESOLVED_DESCRIPTION;
    this.displayEnquetes = this.fetchEnquete();
    this.progressBarColor = this.getStyleValue(
      document.querySelector(".progress_bar_color"),
      "color"
    );
    this.progressBarBackground = this.getStyleValue(
      document.querySelector(".progress_bar_color"),
      "background-color"
    );
    switch (this.enqueteType) {
      case STATUS.UNRESOLVED:
        this.unresolvedMessage = constObj.FEEDBACK_ERROR_MESSAGE
        break;
      case STATUS.SCRIPT_NOT_FOUND:
        this.unresolvedMessage = constObj.SCRIPT_NOT_FOUND_MESSAGE
        break;
      case STATUS.SEARCH_FAILED:
        this.unresolvedMessage = constObj.OTHER_CHOICE_MESSAGE
        break;
    }
    this.displayEnquetes.forEach(element => {
      if (element.answers[0].type === "checkbox") {
        this.checkboxRequiredList.push(element.question.required);
      } else {
        this.checkboxRequiredList.push(null);
      }
    });
  },
  computed: {
    constObj() {
      return this.$store.state.constObj;
    }
  },
  methods: {
    getStyleValue(element, property) {
      if (!element) {
        return null;
      }
      var style = window.getComputedStyle(element);
      var value = style.getPropertyValue(property);
      return value;
    },
    getAnswerLabel(label) {
      const splitList = label.split("imgResource=");
      if (splitList.length === 2) {
        return this.getResourceImg(splitList[1]);
      }
      return label;
    },
    getResourceImg(param) {
      const splitList = param.split(",class=");
      const resourcePath = require("@product/resource/" + splitList[0]);
      let result = "<img src=" + resourcePath + " />";
      if (splitList.length === 2) {
        result = "<img class=" + splitList[1] + "  src=" + resourcePath + " />";
      }
      return result;
    },

    handleChange(e, index) {
      let label = "";
      switch (e.target.type) {
        case "radio":
          this.progressValue = this.progressValue + this.progressValueUnit;
          label = e.target.parentElement.querySelector("span").innerText;
          this.setResult(e, label, index);
          break;
        case "checkbox":
          this.progressValue = this.progressValue + this.progressValueUnit;
          label = e.target.parentElement.querySelector("span").innerText;
          this.setResultCheckbox(e, label, index);
          break;
        case "textarea":
          label = "textarea";
          this.setResult(e, label, index);
          break;
      }
      this.progressValue = this.calculateProgressValue(this.state);
    },

    inputTextarea(e, index) {
      const label = "textarea";
      this.setResult(e, label, index);
      this.progressValue = this.calculateProgressValue(this.state);
    },

    setResult(e, label, index) {
      this.state[index] = {
        id: e.target.name,
        value: e.target.value,
        label: label,
        parentLabel: this.displayEnquetes[index].question.label,
        type: e.target.type
      };
    },

    setResultCheckbox(e, label, index) {
      if (typeof this.state[index] === "undefined") {
        this.state[index] = [];
      }
      const isFindIndex = this.state[index].findIndex(
        element => element.value === e.target.value
      );
      if (isFindIndex === -1) {
        this.state[index].push({
          id: e.target.name,
          value: e.target.value,
          label: label,
          parentLabel: this.displayEnquetes[index].question.label,
          type: e.target.type
        });
      } else {
        this.state[index].splice(isFindIndex, 1);
      }
      const required = this.displayEnquetes[index].question.required;
      if (required) {
        this.checkboxRequiredList[index] =
          this.state[index].length > 0 ? false : true;
      }
    },

    calculateProgressValue(obj) {
      let result = 0;
      const progressValueUnit =
        Math.round((100 / this.displayEnquetes.length) * 10) / 10;
      Object.keys(obj).forEach(function(key) {
        if (obj[key].value || obj[key].length > 0) {
          result = result + progressValueUnit;
        }
      });
      this.progressValue = 0;
      return result;
    },

    handleSubmit(e) {
      e.preventDefault();
      this.errorTargetList = [];

      const keys = Object.keys(this.state).sort(function(a, b) {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      });

      let enqueteData = keys.map(key => {
        return this.state[key];
      });
      enqueteData.forEach((element, index) => {
        if (Array.isArray(element) && element.length === 0) {
          enqueteData.splice(index, 1);
        }
      });
      this.displayEnquetes.forEach((element, index) => {
        let hasId = false;
        enqueteData.forEach(resultElement => {
          if (Array.isArray(resultElement)) {
            if (resultElement.length > 0 && element.question.label === resultElement[0].parentLabel) {
              hasId = true;
            }
          } else {
            if (element.question.label === resultElement.parentLabel) {
              hasId = true;
            }
          }
        });
        if (!hasId) {
          if (element.question.required) {
            this.errorTargetList.push('Q' + (Number(index) + 1))
          }
          const obj = {
            id: element.question.name,
            parentLabel: element.question.label,
            type: element.answers[0].type
          }
          switch (obj.type) {
            case "checkbox":
              obj.value = [];
              break;
            case "radio":
              obj.value = null;
              break;
            case "textarea":
              obj.value = "-";
              break;
          }
          enqueteData.push(obj);
        }
      });

      const enqueteTicket = {
        mode: this.enqueteMode,
        values: enqueteData
      };
      if (this.errorTargetList.length === 0) {
        this.submitEnquete({
          enqueteData: enqueteTicket,
          enqueteType: this.enqueteType
        });
      }
    },
    fetchEnquete() {
      if (!this.enquetes) {
        return;
      }
      const displayEnquete =
        this.enqueteType === STATUS.RESOLVED
          ? STATUS.RESOLVED
          : STATUS.UNRESOLVED;
      return this.enquetes[displayEnquete].BOT;
    },
    getEnqueteType() {
      const constObj = this.$store.state.constObj;
      if (constObj.HAS_RESOLVED_ENQUETE && constObj.HAS_UNRESOLVED_ENQUETE) {
        switch (this.$store.state.ticket.ticket.status) {
          case "scriptNotFound":
            return STATUS.SCRIPT_NOT_FOUND;
          case "searchFailed":
            return STATUS.SEARCH_FAILED;
        }
        switch (this.$store.state.ticket.ticket.feedback) {
          case "unresolved":
            return STATUS.UNRESOLVED;
          case "resolved":
            return STATUS.RESOLVED;
        }
      }
      if (constObj.HAS_RESOLVED_ENQUETE) {
        return STATUS.RESOLVED;
      }
      if (constObj.HAS_UNRESOLVED_ENQUETE) {
        return STATUS.UNRESOLVED;
      }
    },
    ...mapActions(["submitEnquete", "closeEnquete"])
  }
};
