import _ from "underscore";
import Hashids from "hashids";
import {
  processPostTicket,
  updateTicketItems,
  initPreviousTicket
} from "common/libs/ticketHandler";

export default {
  state: {
    ticket: {
      client_user_id: new Hashids("", 16).encode(new Date().getTime())
    },
    previousTicket: {}
  },
  mutations: {
    setTicket(state, payload) {
      state.ticket = payload;
    },
    setPreviousTicket(state, payload) {
      state.previousTicket = payload;
    }
  },
  actions: {
    async postTicket({ state, commit }, addParams) {
      const { ticket, previousTicket } = await processPostTicket({
        ticket: state.ticket,
        previousTicket: state.previousTicket,
        addParams: addParams
      });
      commit("setTicket", ticket);
      commit("setPreviousTicket", previousTicket);
    },
    updateTicketItems({ state, commit }, addParams) {
      const newTicket = updateTicketItems({
        ticket: state.ticket,
        addParams: addParams
      });
      commit("setTicket", newTicket);
    },
    initPreviousTicket({ commit }) {
      commit("setPreviousTicket", initPreviousTicket());
    }
  }
};
