import { eventBus } from "@/eventBus";

export default {
  props: ["userInputChoices", "isIphone"],
  data: function() {
    return {
      style: {
        bottom: this.isIphone ? "91px" : "55px"
      }
    };
  },
  methods: {
    selectSuggest(choice, key) {
      this.$emit("updateSuggestionParams", choice, key);
      this.$emit("selectSuggest", key);
      eventBus.$emit("clearInputMessage");
      this.$emit("clearSuggestion");
    },
    suggestBottomUp() {
      this.$data.style.bottom = "91px";
    },
    suggestBottomDown() {
      this.$data.style.bottom = "55px";
    }
  },
  created: function() {
    eventBus.$on("suggestBottomUp", () => {
      this.suggestBottomUp();
    });
    eventBus.$on("suggestBottomDown", () => {
      this.suggestBottomDown();
    });
  }
};
