var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "saichat-user-input", style: _vm.styleField },
    [
      _c("form", { staticClass: "saichat-user-input__form" }, [
        _c("textarea", {
          ref: "sendMessageInput",
          staticClass: "saichat-user-input__form__field",
          class: { lessFont: _vm.isSP },
          attrs: { type: "text", placeholder: _vm.placeholder },
          domProps: { value: _vm.inputMessage },
          on: {
            input: [
              function($event) {
                _vm.inputMessage = $event.target.value
              },
              _vm.onInput
            ],
            click: function($event) {
              $event.preventDefault()
              return _vm.onFocusInput($event)
            },
            blur: _vm.offFocusInput,
            keydown: _vm.onKeydownInput,
            keyup: _vm.onKeyupInput,
            compositionstart: _vm.onConpositionStart,
            compositionend: _vm.onCompositionend
          }
        }),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "saichat-user-input__form__send",
            style: _vm.styleSend,
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.sendMessage($event)
              }
            }
          },
          [
            _c("img", {
              staticClass: "send-icon",
              attrs: { src: _vm.getResourceImg(_vm.constObj.INPUT_SEND_ICON) }
            })
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }